/**
 * UsersList
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../general/textInput';
import SelectInput from '../../general/selectInput';
import { withRouter } from 'react-router-dom';
import { graphql, compose, withApollo } from 'react-apollo';
import {
  ALL_USERS,
  GET_ROLES
} from '../../../graphql/types/usersModule/queries';

// my components
import UsersTable from '../usersTable';
import BodyContainer from '../../../components/general/bodyContainer';

import './style.css';

class UsersList extends Component {
  filterDone;

  constructor (props) {
    super(props);
    const initialState = {
      page: 1,
      users: [],
      filter: {},
      filters: [
        {
          field: 'name',
          value: ''
        },
        {
          field: '_role',
          value: ''
        },
        {
          field: 'active',
          value: true
        }
      ],
      isLoader: true,
      finished: false,
      filtered: false,
      userLoad: true,
      filtersCollapsed: true
    };

    initialState.filters.map(({field, value}) => {
      return (initialState[field] = value);
    });

    this.state = initialState;
  }

  updateUsers = async () => {
    if (this.filterDone) clearTimeout(this.filterDone);

    const filter = this.buildFilters(this.state);
    const { page } = this.state;

    if (this.state.filter !== filter) {
      this.filterDone = setTimeout(async () => {
        this.setState({ isLoader: true });
        const users = await this.props.client.query({
          query: ALL_USERS,
          variables: filter
        });

        this.setState({
          page,
          users: users.data.allUsers.data,
          filter,
          finished: true,
          filtered: true,
          isLoader: false
        });
      }, 500);
    } else {
      this.setState({ isLoader: false });
    }
  };

  buildFilters = state => {
    const filters = {};
    
    state.filters.map(({ field }) => {
      if (state[field] !== '') {
        if (field === 'name' && state[field].length < 4) {
          return null;
        } else {
          return (filters[field] = state[field]);
        }
      } else {
        return null;
      }
    });

    filters['page'] = this.state.page;
    
    return filters;
  };

  toProfile = id => {
    this.props.history.push(`/users/${id}`);
  };

  updateField = (name, { value, name: role }) => {
    if (value !== '') {
      value = name === 'active' ? value === 'true' : value;
    }
    if (role) {
      const currentRole = role === 'medico' || role === 'enfermera' ? role : '';
      this.setState(
        { [name]: value, currentRole, page: 1, finished: false },
        () => this.updateUsers()
      );
    } else {
      this.setState({ [name]: value, page: 1, finished: false }, () =>
        this.updateUsers()
      );
    }
  };

  scrolled = async o => {
    if (
      o.target.offsetHeight + o.target.scrollTop >=
      o.target.scrollHeight - 30
    ) {
      const { users } = this.state;
      const { userLoad, isLoader } = this.state;
      if (userLoad && !isLoader) {
        this.setState({ isLoader: true });
        this.loadUsers(users);
      }
    }
  };

  loadUsers = async users => {
    const filter = this.buildFilters(this.state);

    const response = await this.props.client.query({
      query: ALL_USERS,
      variables: filter
    });
    if (response.data.allUsers.success) {
      const data =
        response.data.allUsers.data.length > 0
          ? response.data.allUsers.data
          : [];
      users = users.concat(data);
      let { userLoad } = this.state;
      if (data.length < 30) {
        userLoad = false;
      }
      this.setState({
        isLoader: false,
        users,
        page: this.state.page + 1,
        userLoad,
        finished: true
      });
    }
  };

  toggleFilters = () => {
    this.setState({ filtersCollapsed: !this.state.filtersCollapsed });
  };

  componentDidMount () {
    const { users } = this.state;
    this.loadUsers(users);
  }
  render () {
    const { children, getRoles } = this.props;
    const { users, finished, isLoader } = this.state;
    if (getRoles && getRoles.loading) {
      return (
        <BodyContainer isLoader={true}>
          <div>Loading</div>
        </BodyContainer>
      );
    }
    if (getRoles && getRoles.error) {
      return <div>Error</div>;
    }
    const roles = getRoles.allRole.data;
    const { filtersCollapsed } = this.state;
    return (
      <BodyContainer isLoader={isLoader} id="user-patient-list">
        <div
          className={`users-list contentListUser ${filtersCollapsed ? 'filters-collapsed' : ''}`}
        >
          <div className="flex j-c a-i-c search-container">
            <div className="search-field-icon">
              <TextInput
                iconBefore="search"
                value={this.state.name}
                type="text"
                name="name"
                label={
                  window.innerWidth < 780? 'Buscar': 'Ingresa nombre o identificación del usuario'
                }
                updateField={this.updateField}
              />
            </div>
            <div className="users-filters-toggle text-right ripple-gray">
              <span
                className="large material-icons clickable"
                onClick={this.toggleFilters}
              >
                toc
              </span>
            </div>
          </div>
          <div className="user-filters-container flex j-c a-i-c f-w">
            <div className="filter-field">
              <SelectInput
                value={this.state._role}
                name="_role"
                label="Rol"
                options={roles}
                updateField={this.updateField}
              />
            </div>
            <div className="filter-field">
              <SelectInput
                value={this.state.active.toString()}
                name="active"
                label="Estado"
                options={[
                  { _id: true, name: 'activo' },
                  { _id: false, name: 'inactivo' }
                ]}
                updateField={this.updateField}
              />
            </div>
          </div>
          <UsersTable
            scrolled={this.scrolled}
            users={users}
            toProfile={this.toProfile}
            finished={finished}
          />
          {children}
        </div>
      </BodyContainer>
    );
  }
}
UsersList.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  getRoles: PropTypes.object,
  children: PropTypes.element
};
UsersList.defaultProps = {
  children: []
};

export default compose(graphql(GET_ROLES, { name: 'getRoles' }))(
  withApollo(withRouter(UsersList))
);
