import gql from 'graphql-tag';

export const GET_SISPRO_OPTIONS = gql`
    query getSisproOptions {
        getSisproOptions {
            data {
                code
                group
                name                   
            }
            errors {
                message
                path
            }
            success
        }
    }
`;

export const GET_RELATED_SISPRO_OPTIONS = gql`
    query getRelatedSisproOptions($code: String!, $group: String!) {
        getRelatedSisproOptions(code: $code, group: $group) {
            data {
                code
                group
                name
                related {
                    code
                    name
                }      
            }
            errors {
                message
                path
            }
            success
        }
    }
`;

export const GET_COUNTRIES_OPTIONS = gql`
query getCountries(
    $filters: FiltersCountry
) {
    getCountries(
        filters: $filters
    ) {
        data {
            code
            name   
        }
        errors {
            message
            path
        }
        success
    }
}
`;

export const GET_STATES_OPTIONS = gql`
    query getStates {
        getStates {
            data {
                _id
                code
                name 
            }
            errors {
                message
                path
            }
            success
        }
    }
`;

export const GET_CITIES_OPTIONS = gql`
query getCities(
    $filters: FiltersCity
) {
    getCities(
        filters: $filters
    ) {
        data {
            _id
            code
            name
            state 
        }
        errors {
            message
            path
        }
        success
    }
}`;

export const GET_CUPS_CODE = gql`
query getCupsCode(
    $appointmentId: ID
) {
    getCupsCode(
        appointmentId: $appointmentId
    ) {
        code
        success
    }
}
`;

export const GET_COST_CENTERS = gql`
	query getCostCenters {
		getCostCenters {
			success
			errors {
				path
				message
			}
			data {
				_id
				name
				code
			}
		}
	}
`

export const GET_APPOINTMENTS_COST_CENTER = gql`
query getAppointmentCostCenter(
    $appointmentId: ID!
) {
    getAppointmentCostCenter(
        appointmentId: $appointmentId
    ) {
        data {
            code
            name
        }
        success
    }
}
`;

export const GET_PROFESSIONS = gql`
query getProfessions(
    $name: String
) {
    getProfessions(
        name: $name
    ) {
        data {
            _id
            code
            name
        }
        errors {
            message
            path
        }
        success
    }
}`;

export const GET_CUPS_CODES = gql`
	query getCupsCodes($filters: cupsFilters, $pagination: cupsPagination) {
		getCupsCodes(filters: $filters, pagination: $pagination) {
			success
			errors {
				path
				message
			}
			data {
                _id
				service
                eps
                code
			}
            total
		}
	}
`;